
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.navbar-custom{
    background-color: #ffffff;
    border:none;
    border-radius: 0px;
}

.navbar-brand-custom{
 height:inherit;
}

.navbar-nav-custom{
 margin-top: 22px;
}

.navbar-default .navbar-nav > .active > a,.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus,.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
    color: #000000;
    background-color: $bspoke-contact-color;
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.navbar{
    margin-bottom:0px;
}

.grey-bg{
    background-color:$bspoke-grey-color;
    color:#fff;
}

.white-bg{
    background-color:$body-bg;
    color:#000000;
}

.green-bg{
    background-color:$bspoke-contact-color;
    color:#000000;
}

.bg-sections{
    position: relative;
    padding-bottom: 100px;
}

.diagonal-section::after{
  background-position: center center;
  background-repeat: no-repeat;
  content: '';
  height: 150px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  bottom: 0;
  margin-bottom: -1px; //Stops the background colour appearing on mobile devices
}

.ltr-to-white-section::after{
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='150' width='2800' fill='#{$body-bg}' fill-opacity='1'%3E%3Cpolygon points='0,150 2800,150 0,0'%3E%3C/polygon%3E%3C/svg%3E");
}

.rtl-to-white-section::after{
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='150' width='2800' fill='#{$body-bg}' fill-opacity='1'%3E%3Cpolygon points='0,150 2800,150 2800,0'%3E%3C/polygon%3E%3C/svg%3E");
}

.ltr-to-green-section::after{
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='150' width='2800' fill='#{$bspoke-contact-color}' fill-opacity='1'%3E%3Cpolygon points='0,150 2800,150 0,0'%3E%3C/polygon%3E%3C/svg%3E");
}

.rtl-to-green-section::after{
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='150' width='2800' fill='#{$bspoke-contact-color}' fill-opacity='1'%3E%3Cpolygon points='0,150 2800,150 2800,0'%3E%3C/polygon%3E%3C/svg%3E");
}

.ltr-to-grey-section::after{
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='150' width='2800' fill='#{$bspoke-grey-color}' fill-opacity='1'%3E%3Cpolygon points='0,150 2800,150 0,0'%3E%3C/polygon%3E%3C/svg%3E");
}

.rtl-to-grey-section::after{
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='150' width='2800' fill='#{$bspoke-grey-color}' fill-opacity='1'%3E%3Cpolygon points='0,150 2800,150 2800,0'%3E%3C/polygon%3E%3C/svg%3E");
}

#footer{
 background-color:$bspoke-contact-color;
 padding-top: 30px;
 padding-bottom: 30px;
}

#footer ul > li {
    display: inline-block;
    margin-left: 15px;
}

#footer ul > li > a {
    color: #f1f1f1;
}

.first-section-content{
    padding-top:20px;
}

.text-align-right{
    text-align: right;
}

.text-align-center{
    text-align:center;
}

.no-top-padding{
    padding-top: 0px;
    margin-top: 0px;
}

@media (min-width: 768px){

    .header-contact-tel{
        padding-left: 10%;
    }
    .header-contact-email{
        float:right
    }

}

.section-icon{
    padding-top: 5%;
}

.section-icon-non-first{
    padding-top: 5%;
    margin-top:5%;
    margin-bottom:10%;
}

.icon-bg{
    height: 120px;
    width: 120px;
    line-height: 120px;
    color:$bspoke-contact-color;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    border-radius: 50%;
    -webkit-transition: 500ms;
    -moz-transition: 500ms;
    -o-transition: 500ms;
    transition: 500ms;
}

.page-top-image{
    margin: 0 auto;
    // max-height: 400px;
}

.page-top-image-padding{
    max-height: 450px;
    padding-top: 25px;
    padding-bottom: 25px;
}